import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import Image from '../../components/Image';
import { ImageOverlay } from '../../utils';
import { HTMLContent } from '../../components/Content';
import LinkComponent from '../../components/LinkComponent';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const EventsPageTemplate = ({
  content,
  helmet,
  title,
  subTitle,
  image
}) => (
  <div>
    {helmet || ''}
    <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
      <h1 className='title page-title'>
        <hr />
        {title}
      </h1>
      <ImageOverlay overlay={image.overlay} />
    </BackgroundImage>

    <div className='page-container container content'>
      <div className='all-niseko-title-section'>
        <div className='all-niseko-title'>{subTitle}</div>
        <HTMLContent content={content} />
      </div>
    </div>
  </div>
);

const EventsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <EventsPageTemplate
        content={frontmatter.content}
        location={location}
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        image={frontmatter.heroImageObject}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default EventsPage;

export const pageQuery = graphql`
  query EventsPageQueryJP {
    markdownRemark(frontmatter: { templateKey: { eq: "events-page-jp" } }) {
      frontmatter {
        title
        subTitle
        heroImageObject {
          image
          overlay
        }
        seoObject {
          metaTitle
          metaDescription
        }
        content
      }
    }
  }
`;
